// Here you can add other styles
.sidebar.sidebar-fixed {
    background: #000 !important;
}
a.nav-link{
    color: #fff !important;
}
.breadcrumb a {
    color: #000;
    text-decoration: none;
}
.bg-info {
    background: #f5e003 !important;
}
ul.nav-group-items li a {
    color: #000 !important;
    background: #f5e003 !important;
}
.simplebar-content li:hover {
    background: #e5b611;
}
.sc-hKMtZM .btn {
    min-width: 90px !important;
    margin: 8px 0px !important;
}
.sc-hKMtZM {
    flex: 1 !important;
}


.sc-dmRaPn.gelpCx, .iZzUdc {
    margin-top: 16px;
}
.gFYXSL .btn-primary {
    background: #000;
    border: #000;
}
.gFYXSL .btn-primary:hover {
    background: #e5b611;
    border: #e5b611;
}
.gFYXSL .btn-danger {
    background: #e5b611;
    border: #e5b611;
}
.gFYXSL .btn-danger:hover {
    background: #000;
    border: #000;
    color:#e5b611;
}
.btn-success {
    background: #f5e003;
    border: #f5e003;
}
.btn-success:hover {
    background: #000;
    border: #000;
    color:#e5b611;
}
.eUeqdG {
    font-size: 14px;
    font-weight: bold;
    padding: 20px 0px;
}
// .cCKpPz, .faVNZf {
//     grid-template-columns: repeat(8, 12%);
//     display: grid !important;
// }
.faVNZf {
    border-bottom: 3px solid #000 !important;
}
button.header-toggler svg {
    width: 30px !important;
    height: 30px !important;
}
.rbc-event {
    color: #000 !important;
    background: #f5e003 !important;
}
.rbc-header {
    padding: 10px !important;
    background: #000;
    color: #fff;
}
span.rbc-btn-group button {
    background: #000;
    color: #fff;
}
span.rbc-btn-group button:hover {
    border-color: #f5e003 !important;
    background: #f5e003 !important;
}
button.rbc-active{
    border-color: #f5e003 !important;
    background: #f5e003 !important;
    color: #000 !important;
}
.modal-body {
    padding: 20px !important;
}
.modal-body .card {
    border: none !important;
}
form.row.needs-validation button.btn.btn-primary {
    border-color: #f5e003 !important;
    background: #f5e003 !important;
    color: #000 !important;
}
.form-check-input:checked {
    background-color: #000 !important;
    border-color: #000 !important;
}
button.btn.btn-danger {
    border-color: #f5e003 !important;
    background: #f5e003 !important;
}
button.btn.btn-secondary {
    background: #000;
    color: #fff;
    border-color: #000 !important;
}
.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
    margin-top: 20px;
}
#mui-5-label{
    margin-top: 4px;
}
#mui-6-label{
    margin-top: 4px;
}
.css-j5h6pi-MuiPopper-root{
    z-index: 999999 !important;
}
