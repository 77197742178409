body {
  font-size: 16px;
  font-family: "Avantt";
}

@font-face {
  font-family: "Avantt_semibold";
  src: url("../fonts/Avantt-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Avantt_bold";
  src: url("../fonts/Avantt-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Avantt_light";
  src: url("../fonts/Avantt-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Avantt";
  src: url("../fonts/Avantt-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

header {
  position: sticky;
  top: 0;
  z-index: 10000;
  background: #000;
  margin-bottom: -4px;
}

.hidden-events {
  display: none !important;
}

.key-links-main a {
  text-decoration: underline !important;
}

.tab-content-main {
  display: inline-block;
  width: calc(100% - 52px);
  margin-left: 26px;
}
.tools-heading {
  top: -65px;
  position: relative;
}
.modal {
  z-index: 100000 !important;
}

.no-padding {
  padding: 0 !important;
}

.lcm-timeline th {
  border: 2px solid #fff;
}

.justify-center {
  justify-content: center;
}

.d-wrap {
  flex-wrap: wrap;
}

.process_works .d-flex {
  row-gap: 90px !important;
  justify-content: center;
}

.process_works .card-details {
  flex: 0 0 23%;
}

ul.dropdown-menu {
  border: 1px solid rgba(249, 249, 249, 0.4);
  background: #333;
  /* border-radius: 25px; */
  right: -25px;
  padding: 21px 0;
  top: 43px;
}

ul.dropdown-menu Link {
  padding: 8px 20px;
}

ul.dropdown-menu a.dropdown-item:hover {
  background: none;
  color: #f2d812;
}

.yellow-btn,
.sc-dkzDqf {
  cursor: pointer;
}

ul.dropdown-menu:before {
  border-color: rgba(249, 249, 249, 0);
  border-bottom-color: rgba(249, 249, 249, 0.4);
  border-width: 11px;
  margin-left: -11px;
}

ul.dropdown-menu:after,
ul.dropdown-menu:before {
  bottom: 100%;
  left: 75%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

ul.dropdown-menu:after {
  border-color: rgba(51, 51, 51, 0);
  border-bottom-color: #333;
  border-width: 10px;
  margin-left: -10px;
}

table {
  border: 1px solid #80808014;
  width: 100%;
}

table th {
  background: #f2da23;
}

table th,
table td {
  padding: 8px;
}

.logo-header img {
  width: 150px;
}

.logo-header span {
  display: inline-block;
  color: #f2d812;
  font-family: "Avantt_semibold";
  /*border-left: 1px solid #ccc;*/
  /*margin-left: 10px;*/
  /*padding-left: 10px;*/
  margin-top: 4px;
  vertical-align: top;
}

.modal-feedback .modal-title img {
  width: 200px;
}

.modal-feedback .modal-header {
  background: #000 !important;
}

.modal-feedback .modal-body h4 {
  font-size: 20px;
  text-align: center;
  line-height: 31px;
}

.modal-feedback .react-stars {
  text-align: center;
  margin: 15px auto;
}

.modal-feedback .btn-close {
  color: #fff;
  background-color: #fff;
}

.modal-feedback .react-stars span {
  margin: 0 4px;
}

.satisfied-most {
  padding: 0 20px;
}

.satisfied-most span.most {
  float: right;
}

.review {
  padding: 20px;
}

.review textarea {
  width: 100%;
  height: 150px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.submit {
  text-align: center;
  margin-bottom: 20px;
}

.submit .btn {
  background: transparent linear-gradient(270deg, #f5e003 0%, #d6a732 100%) 0%
    0% no-repeat padding-box;
  border: 0;
  color: #000;
  border-radius: 10px;
  text-transform: uppercase;
  padding: 10px 60px;
}
/* .nav-header-middle>li:hover>ul li a {
	font-size: 15px;
	font-family: 'Avantt_light';
	text-decoration: none;
} */
.nav-header-middle li a {
  color: #000;
  font-size: 15px;
  font-family: "Avantt_semibold";
  padding: 18px 30px !important;
}

ul.nav {
  flex: 0 0 56%;
}

a.logo-header,
.mobile-menu-icons {
  flex: 0 0 22%;
}

a {
  cursor: pointer;
}

.nav-header-middle li a:hover {
  color: #f2d812 !important;
}

.nav-header-middle > li:hover > ul li {
  margin: 10px 0 0 0;
}
.nav-header-middle > li:hover > ul li a {
  padding: 18px 0px !important;
  text-decoration: none;
}

.nav-header-middle > li {
  position: relative;
}

.nav-header-middle > li > ul {
  display: none;
}

.nav-header-middle > li:hover > ul {
  display: block;
  position: absolute;
  right: -250px;
  top: 100%;
  background: #fff;
  border: 1px solid rgba(249, 249, 249, 0.4);
  z-index: 1000;
  width: 1100px;
  /* border-radius: 25px; */
  list-style: none;
  padding: 20px;
}

.nav-header-middle > li:hover > ul > li {
  float: left;
  width: 100%;
  vertical-align: top;
}

.nav-header-middle > li:hover > ul li a:hover {
  text-decoration: underline;
  color: #f2d812;
}

.nav-header-middle > li:hover > ul:after,
.nav-header-middle > li:hover > ul:before {
  bottom: 100%;
  left: 72%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

/* .nav-header-middle>li:hover>ul:before {
	border-color: rgba(249, 249, 249, 0);
	border-bottom-color: rgba(249, 249, 249, 0.4);
	border-width: 11px;
	margin-left: -11px;
} */

.heading-sub-menu {
  margin: 10px 0 10px 0;
  font-family: "Avantt_bold";
  text-transform: uppercase;
  font-size: 15px;
  color: #000;
}

/* .nav-header-middle>li:hover>ul:after {
	border-color: rgba(51, 51, 51, 0);
	border-bottom-color: #1f1f1f;
	border-width: 10px;
	margin-left: -10px;
} */

.masonry-with-columns {
  columns: 3 300px;
  column-gap: 1rem;
}

.masonry-with-columns > div {
  width: 150px;
  color: white;
  margin: 0 1rem 1rem 0;
  display: inline-block;
  width: 100%;
}

ul.main-topics {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.main-topics li a {
  color: #000;
  font-family: "Avantt_bold";
  text-transform: uppercase;
  text-decoration: none !important;
}

.nav-header-middle li a.active {
  color: #f2d812;
}

.nav-header-middle li a.active:hover {
  color: #f2d812;
}

.nav-header-middle li a:hover,
.nav-header-middle li a:focus {
  color: #fff;
}

.menu-header-right {
  padding: 0;
  margin: 0;
}

.menu-header-right li {
  display: inline-block;
  padding: 0 0 0px 25px;
}

.menu-header-right li a {
  color: #f2d812;
  display: block;
}

.link-common {
  text-align: center;
}

.view_sop:hover {
  color: #f2d812;
}

.link-main {
  display: inline-block !important;
  background: #000 !important;
  color: #fff !important;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.link-main:hover,
.faq-doc:hover {
  background: #f2d812 !important;
  color: #000 !important;
}

.menu-header-right li a.bell i {
  font-size: 21px;
  position: relative;
  top: 2px;
}

.menu-header-right li a i {
  font-size: 19px;
}

.menu-header-right .dropdown-menu {
  padding: 0;
}

.menu-header-right .dropdown .dropdown-toggle {
  background: none;
  border: 0;
  color: #f2d812;
  position: relative;
  top: 3px;
}

.menu-header-right .dropdown .dropdown-toggle:after {
  display: none;
}

.menu-header-right .dropdown .dropdown-toggle i {
  font-size: 25px;
}

.menu-header-right .dropdown-menu li {
  padding-left: 0;
  width: 100%;
}

.footer-btm {
  background: #000;
  border-top: 1px solid #fff;
  border-bottom: 3px solid #f2d812;
}

.footer-btm .footer-logo {
  height: 25px;
}

.privacy-policy {
  color: #fff;
}

.privacy-policy a {
  color: #fff;
}

.privacy-policy a:hover {
  color: #fff;
}

.footer-upscale-logo {
  height: 60px;
}

.banner-home {
  position: relative;
  background-image: url(../images/manager_home_banner.png);
  background-size: cover;
  background-position: bottom;
  padding-bottom: 20px;
}

/* 
.banner-home:before {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	content: "";
	background: rgba(0, 0, 0, 0.7);
} */
/* .overlay{
	z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    background: rgba(0, 0, 0, 0.7);
} */
/* .banner-home-details {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
} */

.banner-home-details .user-name {
  padding-top: 40px;
  color: #fff;
  line-height: 20px;
}

.banner-home-details .user-name span {
  font-size: 35px;
  font-family: "Avantt_semibold";
}

.banner-home-details .user-name h3 {
  font-size: 30px;
  margin-top: 0;
  text-transform: uppercase;
  color: #e9ca15;
}

.search-banner {
  padding-top: 20px;
}

.search-banner p.head {
  color: #fff;
  font-size: 19px;
}

.search-form {
  position: relative;
}

.search-form .form-group {
  position: relative;
}

.search-form .form-group .form-control {
  background: rgba(0, 0, 0, 0.5);
  /* border: 0; */
  padding: 13px 10px;
  font-size: 17px;
  color: #fff;
}

.search-form .form-group .search-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 20px;
  color: #fff;
  border-left: 1px solid #999;
  padding-left: 10px;
}

.search-tags {
  padding: 10px 0;
  margin: 0;
}

.search-tags li {
  color: #f2d812;
  display: inline-block;
  padding-right: 25px;
  font-size: 18px;
}

.search-tags li a {
  color: #fff;
  text-decoration: none;
}

.banner-right-details {
  padding: 40px 0 0 50px;
}

.banner-right-details .list-banner a {
  display: table;
  position: relative;
  text-align: center;
  padding: 0;
  background: rgba(0, 0, 0, 0.5);
  height: 180px;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #5c5c5c;
}

.banner-right-details .list-banner.list-btm-banner a .inner-bg h4 {
  color: #f2d812;
  min-height: inherit;
}

.curser-pointer i {
  cursor: pointer;
}

.banner-right-details .list-banner a .inner-bg {
  /*background: #f2d812;
	position: relative;
	top: -15px;
	left: -15px;
	padding: 20px;*/
  display: table-cell;
  vertical-align: middle;
}

.banner-right-details .list-banner.list-btm-banner a {
  height: 130px;
}

.list-banner {
  width: calc(100% - 20px) !important;
  margin: 0 20px 20px 20px;
}

.banner-right-details .list-banner a .inner-bg h4 {
  margin: 0 0 5px 0;
  font-size: 19px;
  min-height: 48px;
  font-family: "Avantt_semibold";
}

.banner-right-details .list-banner.treding-heading-main a .inner-bg h4 {
  font-family: "Avantt", sans-serif;
  padding: 0 10px;
  font-size: 18px;
}

.add-a-reminder {
  background: #f2d812;
  text-align: center;
  color: #000;
  margin-top: 10px;
  padding: 5px 0;
  border-radius: 20px;
}

.banner-right-details .list-banner a .inner-bg p {
  margin: 0;
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.list-btm-banner a {
  width: 100%;
}

.All-topics {
  text-align: center;
  font-family: "Avantt_semibold";
  color: #fff;
  padding-top: 0px;
}

.All-topics h4 {
  margin-bottom: 0;
}

.treding-heading-main {
  background: rgba(0, 0, 0, 0.5);
  height: 180px;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #5c5c5c;
}

.treding-heading {
  padding: 15px;
}

.treding-heading span {
  color: #f2d812;
  font-size: 18px;
  padding-right: 10px;
  font-family: "Avantt_semibold", sans-serif;
}

.treding-heading img {
  width: 20px;
}

.treding-heading-main .carousel {
  position: relative;
  padding: 0 10px;
}

.treding-heading-main .w-50 {
  width: calc(50% - 20px) !important;
  float: left;
  margin: 0 10px;
}

.treding-heading-main a {
  height: 110px !important;
}

.All-topics .scroll-down {
  color: #f2d812;
  font-size: 35px;
}

.handbook-list-home {
  padding: 30px 0;
  background-color: #fff;
}

.handbook-list-home {
  position: relative;
}

.handbook-list-home .abi-pattern {
  position: absolute;
  bottom: 30px;
  right: 0;
  width: 48%;
}

.handbook-list-home .container {
  position: relative;
  z-index: 9;
}

.handbook-list-home .heading {
  color: #000;
  background: transparent linear-gradient(270deg, #f5e003 0%, #d6a732 100%) 0%
    0% no-repeat padding-box;
  text-align: center;
  font-size: 30px;
  padding: 10px 15px;
  font-weight: bold;
  display: inline-block;
  border-radius: 10px;
}

.handbook-list-home p {
  margin-bottom: 50px;
}

.emplo-movemets li {
  width: 50% !important;
}

.handbook-list-home div#tabs-content {
  background: #fff;
}

/*.tabs {
	width: 600px;
	background-color: #09F;
	border-radius: 5px 5px 5px 5px;
}*/
ul#tabs-nav {
  list-style: none;
  margin: 0;
  padding: 5px;
  overflow: auto;
}

ul#tabs-nav > li {
  float: left;
  cursor: pointer;
  width: 16.6667%;
  margin: 0;
  padding: 0 20px;
  background: none;
}

ul#tabs-nav > li.active a:hover,
ul#tabs-nav > li.active a {
  color: #000;
  background: #f2d812;
}

ul#tabs-nav > li.active .arrow_box {
  position: relative;
  background: #f2d812;
}

ul#tabs-nav > li.active .arrow_box:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(242, 216, 18, 0);
  border-bottom-color: #f2d812;
  border-width: 15px;
  margin-left: -15px;
}

#tabs-nav > li > a:hover {
  color: #fff;
  background: #000;
}

#tabs-nav > li > a {
  text-decoration: none;
  color: #000;
  font-size: 17px;
  min-height: 73px;
  display: flex;
  text-align: center;
  margin-bottom: 20px;
  padding: 0;
  text-transform: uppercase;
  font-family: "Avantt_semibold", sans-serif;
  background-color: #dfdfdf;
  /* -webkit-transition: background-color 800ms linear;
	-ms-transition: background-color 800ms linear;
	transition: background-color 800ms linear; */
  align-items: center;
  border-radius: 5px;
  justify-content: center;
  padding: 0 10px;
}

.tab-content {
  border: 3px solid #f2d812;
  margin-bottom: 30px;
  border-radius: 10px;
  background: #fff;
}

.card-details a {
  color: #000;
  font-family: "Avantt", sans-serif;
  text-decoration: none;
  font-size: 18px;
  padding: 7px 10px;
  display: block;
  border-radius: 5px;
  margin: 5px 0;
  background: #eaeaea;
}

.process-list-main a {
  color: #000;
}

.process-list-main a:hover {
  color: #d3a238;
}

.details-att {
  text-align: center;
  margin: 0 auto;
  border: 1px solid #707070;
  border-radius: 5px;
  background: #fff;
  padding: 20px 50px;
}

.details-att a {
  color: #000;
}

.details-att a:hover {
  color: #f2d812;
}

.box-inner a {
  color: #000;
}

.box-inner a:hover {
  color: #f2d812;
}

.detail-att a {
  color: #000;
}

.detail-att a:hover {
  color: #f2d812;
}

.card-details a:hover,
.process-list-main a:hover {
  color: #f2d812;
}

.listen-discuss a {
  color: #000;
}

.listen-discuss a:hover {
  color: #f2d812;
}

p.hire-p b {
  color: #f2d812;
}

p.hire-p {
  max-width: 600px;
  text-align: left;
  margin: 0 auto;
}

.details a {
  color: #000;
  font-family: "Avantt", sans-serif;
  text-decoration: none;
  font-size: 18px;
  padding: 7px 10px;
  display: block;
  border-radius: 5px;
  margin: 5px 0;
  background: #eaeaea;
}

.details a:hover {
  color: #f2d812;
}

.att a {
  color: #000;
  font-family: "Avantt", sans-serif;
  text-decoration: none;
  font-size: 18px;
  padding: 7px 10px;
  display: block;
  border-radius: 5px;
  margin: 5px 0;
  background: #eaeaea;
}

.att a:hover {
  color: #f2d812;
}
.videoContainer video {
  width: 100%;
  height: 360px;
  border: 1px solid #eee;
}
.pdfdata i {
  font-size: 21px;
  color: #000;
}
a.faq-doc.blk-btn {
  display: inline-block;
  width: 200px;
  margin: 0 auto;
}
a.faq-doc.blk-btn:hover {
  background: #000 !important;
}

.blk-btn i {
  margin-right: 8px;
}
.blk-btn {
  background: #000;
  min-width: 100px;
  padding: 8px 20px;
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 10px;
  color: #fff !important;
  text-decoration: none;
  border-radius: 5px;
  border: 0;
}

a.yellow-btn.v-campus {
  width: 100%;
  display: block !important;
  max-width: 300px;
  margin-top: 30px;
}

/* a.yellow-btn.v-campus:hover {
	color: #fff;
} */

p.this-hand {
  margin-bottom: 20px;
  margin-top: 20px;
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
}

.blk-btn:hover {
  color: #f2d812 !important;
}

.tab-content h2 {
  display: inline-block;
  background: #f2d812;
  color: #000;
  font-size: 22px;
  padding: 10px 15px;
  position: relative;
  top: -49px;
  border: 1px solid #f2d812;
  font-family: "Avantt_semibold", sans-serif;
  margin: 0;
  left: -1px;
  border-radius: 10px 10px 0 0;
}

.handbook-list {
  list-style: none;
  display: inline-block;
  width: 100%;
  padding: 20px;
  margin: 0;
}

.list-banner .carousel-control-prev {
  left: -24px;
}

.list-banner .carousel-control-next {
  right: -24px;
}

.list-banner .carousel-control-prev-icon,
.list-banner .carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 85% 85%;
  background-color: #f2d812;
  border-radius: 50%;
}

.header-custom {
  padding: 5px 0 0px 0;
}

.list-banner .carousel-control-prev,
.list-banner .carousel-control-next {
  width: 50px;
  opacity: 1;
}

.handbook-list li {
  display: inline-block;
  text-align: center;
  width: 33.33%;
  padding: 0 10px;
  vertical-align: middle;
}

.people-cycle li {
  width: 33.33%;
}

.pulse-survey .card-details p {
  margin-top: 0 !important;
}

.handbook-list li a {
  color: #000;
  font-family: "Avantt", sans-serif;
  text-decoration: none;
  font-size: 18px;
  padding: 7px 10px;
  display: block;
  border-radius: 5px;
  margin: 5px 0;
  background: #eaeaea;
}

.handbook-list li a:hover {
  background: #faf191;
}

.carousel-banner-common .carousel-control-next-icon,
.carousel-banner-common .carousel-control-prev-icon {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-repeat: no-repeat;
  background-position: 58%;
  background-size: 75% 75%;
  border-radius: 50%;
  opacity: 1;
  background-color: #000;
  padding: 10px;
}

.carousel-banner-common .carousel-control-next,
.carousel-banner-common .carousel-control-prev {
  opacity: 0.6;
}

.common-banner {
  position: relative;
}

.common-banner:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(230, 182, 17, 0.1);
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27rgba%280, 0, 0, 0.87%29%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27rgba%280, 0, 0, 0.87%29%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e") !important;
}

.treding-heading-main.list-banner .carousel-control-prev,
.treding-heading-main.list-banner .carousel-control-next {
  padding-top: 10px;
  opacity: 1;
}

.common-banner .bannerimg-common {
  width: 100%;
}

.common-banner .banner-details {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: table;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.common-banner .banner-details .banner-details-inner {
  display: table-cell;
  vertical-align: middle;
}

.common-banner .banner-details .banner-details-inner .details > span {
  width: 60px;
  height: 10px;
  display: inline-block;
  background: #000;
}

.common-banner .banner-details .banner-details-inner .details p {
  text-transform: uppercase;
  font-size: 40px;
  font-family: "Avantt_semibold", sans-serif;
  color: #000;
}

.list-common-box {
  background: #000;
  position: relative;
  min-height: 650px;
  padding: 80px 0;
}

.banner-right-details .list-btm-banner a .inner-bg p {
  padding: 0px 15px;
}

.list-common-box .patter-show {
  position: absolute;
  bottom: 70px;
  right: 0;
  width: 40%;
  opacity: 0.3;
}

.list-common-box .list-box {
  width: calc(100% - 30px);
  margin: 0 15px;
  position: relative;
  margin-bottom: 30px;
  z-index: 100;
}

.list-common-box .list-box a {
  display: block;
  background: #fff;
  padding: 10px;
  text-decoration: none;
}

.list-common-box .list-box a .image img {
  width: 100%;
}

.list-common-box .list-box a .details {
  color: #000;
  padding: 20px;
}

.list-common-box .list-box a .details h4 {
  text-transform: uppercase;
  font-family: "Avantt_semibold", sans-serif;
}

.list-common-box .list-box a .details span {
  color: #333;
  font-size: 18px;
  float: left;
  width: 100%;
}

.events-date {
  font-size: 14px !important;
  color: #999 !important;
}

.call-to-action {
  background: #000;
  padding: 30px 0;
}

.call-to-action .heading {
  color: #e6b611;
  text-transform: uppercase;
  padding: 0 15px;
  margin-bottom: 25px;
  font-size: 37px;
  font-family: "Avantt_semibold", sans-serif;
}

ul.call-to-action-list {
  padding: 0 15px;
  display: table;
  width: 100%;
  margin-bottom: 100px;
}

ul.call-to-action-list .right-call {
  display: table-cell;
  vertical-align: middle;
  width: 50%;
  padding: 0 30px;
  color: #fff;
}

ul.call-to-action-list .left-call {
  display: table-cell;
  vertical-align: middle;
  width: 50%;
}

ul.call-to-action-list .left-call img {
  width: 100%;
}

ul.call-to-action-list .right-call p {
  font-size: 20px;
}

ul.call-to-action-list .right-call a {
  color: #fff;
  border: 1px solid #fff;
  font-family: "Avantt", sans-serif;
  font-size: 19px;
  letter-spacing: 1px;
  padding: 12px 65px;
  border-radius: 3px;
  text-decoration: none;
  margin-top: 15px;
  display: inline-block;
}

ul.call-to-action-list .right-call a:hover {
  background-image: linear-gradient(
    to right top,
    #d3a238,
    #deb031,
    #e7c028,
    #efd01b,
    #f5e003
  ) !important;
  border: 1px solid #d3a238 !important;
  color: #000 !important;
}

.sample-table {
  background: #000;
  padding-bottom: 70px;
}

.sample-table .heading {
  color: #e6b611;
  text-transform: uppercase;
  padding: 0 15px;
  font-size: 37px;
  margin-bottom: 25px;
  font-family: "Avantt_semibold", sans-serif;
}

.sample-table .table-sample {
  padding: 0 15px;
  font-size: 18px;
}

.sample-table .table-sample thead {
  text-transform: uppercase;
  font-family: "Avantt_semibold", sans-serif;
}

.sample-flow-chart {
  padding: 70px 0;
}

.sample-flow-chart .heading {
  color: #e6b611;
  text-transform: uppercase;
  padding: 0 15px;
  font-size: 37px;
  margin-bottom: 25px;
  font-family: "Avantt_semibold", sans-serif;
}

ul:not(.checks-slas) li::marker {
  color: #f2d812;
}

.flow-chart-image {
  padding: 0 15px;
}

.common-btm-pattern {
  background: #000;
  position: relative;
  min-height: 400px;
}

.common-btm-pattern img {
  position: absolute;
  bottom: 70px;
  right: 0;
  width: 40%;
  opacity: 0.3;
}

.sample-table .table-sample thead th {
  color: #000;
  background-color: #e4c22d !important;
}

.sample-table .table-sample tbody tr:nth-child(even) td {
  background-color: #fcf5cb !important;
  color: #000;
}

.sample-table .table-sample tbody tr:nth-child(odd) td {
  background-color: #f8eb97 !important;
  color: #000;
}

.table > :not(caption) > * > * {
  box-shadow: none;
}

.sample-table .table-sample .table {
  border: 2px solid #fff;
}

.common-banner .banner-details .banner-details-inner .details .desc {
  text-transform: inherit;
  font-size: 19px;
}

.list-common-box .heading {
  color: #fff;
  padding: 0 15px;
  font-size: 40px;
  margin-bottom: 25px;
  font-family: "Avantt_semibold", sans-serif;
}

.events-1-list.list-common-box .list-box a {
  display: table;
  width: 100%;
}

.events-1-list.list-common-box .list-box a .image {
  display: table-cell;
  width: 150px;
  vertical-align: middle;
  background: #f9f9f9;
  border: 1px solid #eee;
}

.events-1-list.list-common-box .list-box a .details {
  display: table-cell;
  vertical-align: middle;
}
.four-a-framework .link-main {
  border: 1px solid;
}
.list-common-box .sub-heading {
  color: #fff;
  padding: 0 15px;
  font-size: 30px;
  font-family: "Avantt_light";
  margin-bottom: 25px;
}

.leaves-structure p i {
  color: #f2d812 !important;
}
.more-events .scroll-down {
  color: #f2d812;
}

.search-results-main {
  background: #000;
  padding: 70px 0;
}

.search-results-main .results-head {
  color: #f2d812;
  font-family: "Avantt_semibold", sans-serif;
  font-size: 40px;
  margin-bottom: 20px;
}

.three-in-one .card-details {
  flex: 0 0 30%;
}

img.width-auto {
  width: auto;
}

.text-center a {
  color: #000;
}

.text-center a:hover {
  color: #f2d812;
}
.key-links a:hover {
  color: #000 !important;
  border-color: #f2d812 !important;
}
.nav-tabs-results {
  padding: 70px 0;
}

.nav-tabs-results .nav-pills {
  border-bottom: 1px solid #e6b611;
  margin-bottom: 0;
}

.nav-tabs-results .nav-pills .nav-link {
  color: #f2d812;
  font-size: 20px;
  padding: 10px 50px;
  border-bottom: 2px solid transparent;
  border-radius: 0;
}

.nav-tabs-results .nav-pills .nav-item.active .nav-link {
  border-bottom: 2px solid #f2d812;
  background: none;
}

.nav-tabs-results .tab-content {
  margin: 0;
  border: 0;
}

.found-sortby {
  padding: 20px 0;
}

.found-sortby span.found {
  color: #f2d812;
  font-size: 18px;
}

.found-sortby .sort-by {
  float: right;
}

.found-sortby .sort-by span {
  display: inline-block;
  color: #f2d812;
  font-size: 18px;
}

.found-sortby .sort-by .dropdown {
  display: inline-block;
}

.found-sortby .sort-by .dropdown .dropdown-toggle {
  background: none;
  border: 0;
  padding: 0;
}

.found-sortby .sort-by .dropdown .dropdown-toggle .fa-solid {
  color: #f2d812;
}

.found-sortby .sort-by .dropdown .dropdown-toggle::after {
  display: none;
}

.found-sortby .sort-by {
  float: right;
}

.search-results-list {
  padding: 0;
}

.search-results-list.list-common-box .list-box {
  width: 100%;
  margin: 0 0 30px 0;
}

.forums-main {
  background: #000;
  padding: 70px 0;
}

.forums-main .create-new-post {
  background: #f2d812;
  color: #000;
  display: inline-block;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 24px;
  text-decoration: none;
  cursor: pointer;
}

a.my-anchor-css-class {
  color: #f2d812;
}

.post-list {
  list-style: none;
  padding: 30px 0 0 0;
  margin: 0;
}

.post-list li {
  border: 1px solid #fff;
  border-radius: 15px;
  padding: 30px 30px;
  display: inline-block;
  width: 100%;
  color: #fff;
  margin-bottom: 20px;
}

.post-list li .desc {
  float: left;
  width: 56%;
}

.post-list li .desc h4 {
  font-size: 25px;
  margin: 0;
}

.post-list li .desc p {
  margin: 0;
  font-size: 18px;
}

.post-list li .seen {
  float: left;
  width: 7%;
  font-size: 20px;
  padding-top: 10px;
}

.post-list li .seen span {
  padding-left: 5px;
}

.post-list li .commented {
  float: left;
  width: 7%;
  font-size: 20px;
  padding-top: 10px;
}

.post-list li .commented span {
  padding-left: 5px;
}

.post-list li .follow {
  float: left;
  width: 30%;
  text-align: right;
  padding-top: 10px;
}

.post-list li .follow a {
  color: #fff;
  text-decoration: none;
  font-size: 21px;
}

.heading-newpost {
  color: #fff;
  font-size: 24px;
  margin-bottom: 30px;
}

ul.new-posts {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-block;
  width: calc(100% + 30px);
  margin-left: -15px;
}

ul.new-posts li {
  float: left;
  width: 33.33%;
  padding: 0 15px;
  margin-bottom: 30px;
}

ul.new-posts li .post-list-main {
  border: 1px solid #fff;
  padding: 20px;
  border-radius: 10px;
  color: #fff;
}

ul.new-posts li .post-list-main .user-details {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}

ul.new-posts li .post-list-main .user-details .image {
  width: 65px;
  float: left;
}

ul.new-posts li .post-list-main .user-details .image img {
  width: 45px;
}

ul.new-posts li .post-list-main .user-details .details {
  float: left;
}

ul.new-posts li .post-list-main .user-details .details h4 {
  margin: 0;
  font-size: 21px;
}

ul.new-posts li .post-list-main .user-details .details p {
  margin: 0;
  font-size: 14px;
  font-family: "Avantt_light";
}

ul.new-posts li .post-list-main .title-desc h6 {
  font-family: "Avantt_light";
  margin: 20px 0;
}

ul.new-posts li .post-list-main .comment-shortlist {
  border-top: 1px solid #fff;
  margin-top: 40px;
  padding-top: 20px;
  font-size: 20px;
  display: inline-block;
  width: 100%;
}

ul.new-posts li .post-list-main .comment-shortlist .comment {
  float: left;
}

ul.new-posts li .post-list-main .comment-shortlist .comment span {
  padding-right: 5px;
}

ul.new-posts li .post-list-main .comment-shortlist .shortlist {
  float: right;
}

ul.new-posts li .post-list-main .comment-shortlist .shortlist span {
  padding-right: 5px;
}

ul.new-posts li .post-list-main .comment-shortlist .shortlist-admin span {
  padding-right: 5px;
}

ul.new-posts li .post-list-main .comment-shortlist .comment-admin {
  float: left;
  margin-right: 10px;
}

ul.new-posts li .post-list-main .comment-shortlist .comment-admin span {
  padding-right: 5px;
}

ul.new-posts li .post-list-main .comment-shortlist .delete-admin {
  float: right;
}

ul.new-posts li .post-list-main .comment-shortlist .hide-admin {
  float: right;
  padding-right: 10px;
}

ul.new-posts li .post-list-main .comment-shortlist .shortlist-admin {
  width: auto;
}

.search-dropdown {
  z-index: 2;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 55px;
  display: none;
  border-top: 1px solid #000;
  padding: 20px 15px;
  border-radius: 5px;
  left: 0;
  right: 0;
  height: 250px;
  overflow-y: scroll;
}

.search-dropdown h4 {
  font-size: 16px;
  color: #f2d812;
}

.see-more-search {
  background: #000;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  padding: 5px 20px;
  margin: 20px 0 10px 0;
  border-radius: 20px;
}

.see-more-search:hover,
.see-more-search:focus {
  color: #fff;
  text-decoration: none;
}

.search-dropdown ul {
  list-style: none;
  padding: 10px 0 0 0;
  margin: 0;
}

.search-dropdown ul li {
  padding: 10px 0 10px 0;
  font-weight: 600;
  font-size: 17px;
  color: #f2d812;
}

.search-dropdown ul .sectionsplit-contents a {
  color: #fff;
  border: 1px solid #fff;
  display: inline-block;
  padding: 0 10px;
  text-decoration: none;
  border-radius: 15px;
  margin-bottom: 7px;
}

.search-dropdown ul .sectionsplit-contents a:hover {
  background: #f2d812;
  border: 1px solid #f2d812;
  color: #000;
}

.search-dropdown ul li.event {
  /*border: 1px solid #000;
	padding: 3px 40px;
	border-radius: 5px;*/
}

.people-cycle-banner.common-banner:before {
  background: rgba(0, 0, 0, 0.5);
}

.people-cycle-banner .details {
  text-align: center;
}

.people-cycle-banner p {
  color: #f2d812 !important;
  font-size: 22px !important;
  font-family: "Avantt" !important;
  margin-bottom: 0 !important;
}

.people-cycle-banner .desc {
  color: #fff !important;
  font-family: "Avantt_semibold" !important;
  font-size: 39px !important;
}

.common-blw-banner {
  padding: 60px 0;
}

.common-blw-banner .details {
  text-align: center;
  margin: 0 auto;
  border: 1px solid #707070;
  border-radius: 5px;
  background: #fff;
  padding: 20px 50px;
}

.common-blw-banner .details i {
  font-size: 25px;
}

.common-blw-banner .details h5 {
  padding: 10px 0 10px 0;
  font-family: "Avantt_semibold" !important;
  font-size: 30px;
}

.down-arrow-lcm {
  text-align: center;
  padding-top: 50px;
}

ul.com-list {
  max-width: 840px;
  text-align: left;
  margin: 0 auto;
}

.probation-extension ul,
.probation-extension-proces ul {
  list-style: disc !important;
  padding-left: 14px !important;
}

.probation-extension ul li,
.probation-extension-proces ul li {
  border: none !important;
}

.probation-extension ul ul,
.probation-extension-proces ul ul {
  padding-left: 30px !important;
}

.process-list-main {
  background: #fff;
  padding: 25px;
  width: calc(100% - 30px);
  margin-top: 20px;
  border-radius: 5px;
}

.buddy-emp .card-details {
  flex: 0 0 23%;
}

.process-people h3,
.buddy-emp h3 {
  color: #f2d812 !important;
  font-family: "Avantt_semibold" !important;
  text-align: center;
  max-width: 900px;
  margin: 0 auto;
}

.process-list-main ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.process-list-main h4 {
  font-family: "Avantt_semibold" !important;
  font-size: 20px;
  margin-bottom: 24px;
}

.process-list-main h4 i {
  font-size: 10px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}

.process-list-main ul li i {
  position: absolute;
  top: 0px;
  left: -11px;
}

.process-list-main ul li p {
  display: inline-block;
  color: #000;
  vertical-align: top;
  margin-bottom: 0px;
  padding-left: 20px;
}

.process-list-main ul li {
  position: relative;
  border-left: 2px solid #c7c7c7;
  padding-bottom: 20px;
}

.process-list-main ul li:last-child {
  border-left: 0;
  padding-bottom: 0;
}

.assesment-structure {
  padding: 60px 0;
}

.process-list-main ul li:last-child i {
  left: -6px;
}

.assesment-structure h3 {
  text-align: center;
  font-family: "Avantt_semibold" !important;
  margin-bottom: 50px;
}

.assesment-structure .ass-stru-list {
  width: 70%;
  margin: 0 auto;
}

.full-list .ass-stru {
  border: none !important;
  background: #fff !important;
}

.assesment-structure .ass-stru-list .ass-stru ul {
  padding-right: 20px;
}

.assesment-structure .ass-stru-list .ass-stru {
  background: #f2f2f2;
  width: 95%;
  padding-bottom: 15px;
  /* min-height: 170px; */
  border: 1px solid #fff;
}

.assesment-structure .ass-stru-list .ass-stru h4 {
  background: #f2d812 !important;
  padding: 6px 15px;
  height: 60px;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.assesment-structure .ass-stru-list .ass-stru p {
  padding: 5px 15px;
  margin: 0;
}

.assesment-structure-blw h2 {
  text-align: center;
  font-family: "Avantt_semibold" !important;
}

.assesment-structure-blw .model p {
  margin-bottom: 0;
  font-size: 24px;
}

.assesment-structure-blw.spot-recover-main.manager-in-lcm {
  color: #000;
  border-bottom: 0;
}

.l-side,
.r-side {
  background: #f5f5f5;
  padding: 30px;
  border-radius: 20px;
  flex: 1;
}

.r-side a {
  color: #000;
}

.ol-col-2 {
  column-count: 2;
  column-gap: 60px;
}

.rounded-bullets span {
  font-size: 14px;
  font-weight: 600;
  background: #f2d812;
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}

.rounded-bullets li {
  display: -webkit-inline-box;
}

.five-box .box-inner {
  flex: 1;
  border: 2px solid #f2d812;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
}

.spot-recover h3 a {
  text-decoration: none;
  color: #f2d812;
}

.spot-recover h3 a:hover {
  color: #fff;
}

.rounded-bullets li {
  border: none !important;
}

.six-box .box-inner {
  flex: 0 0 31%;
  border: 2px solid #f2d812;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
}

.box-inner {
  border: 2px solid #f2d812;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
}

.three-box .box-inner {
  width: 23%;
  border: 2px solid #f2d812;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
}

.underline {
  width: 70px !important;
  height: 7px;
  display: inline-block;
  background: #f2d812 !important;
  margin: 0 auto;
}

.assesment-structure-blw .model span {
  width: 70px;
  height: 7px;
  display: inline-block;
  background: #f2d812 !important;
}

.assesment-structure-blw .model {
  text-align: center;
  margin-top: 30px;
}

.assesment-structure-blw .model .desc {
  padding: 0 200px;
  font-size: 15px;
  color: #d9d9d9;
}

.assesment-structure-blw .assesment-structure-blw-list {
  display: table;
  width: 100%;
  list-style: none;
  margin: 25px 0;
  background: #fff;
  color: #000;
  padding: 0;
}

.assesment-structure-blw .assesment-structure-blw-list li {
  display: table-cell;
  padding: 15px;
  vertical-align: middle;
  border-left: 1px solid #ccc;
}

.manager-in-lcm {
  background: #f6f6f6;
  padding: 60px 0;
}

.manager-in-lcm .whatis {
  text-align: center;
}

.manager-in-lcm .whatis h3 {
  font-family: "Avantt_semibold" !important;
}

.manager-in-lcm .whatis p {
  padding: 0 100px;
  margin-bottom: 50px;
}

.mx-900 {
  max-width: 900px !important;
  margin: 0 auto;
}

table.leave-table a {
  color: #000;
  text-decoration: none;
  font-weight: 600;
}

table.leave-table a:hover {
  color: #f2d812;
}

.mx-500 {
  max-width: 500px;
  margin: 0 auto;
}

.card-details {
  background: #fff;
  color: #000 !important;
  padding: 20px 15px;
  flex: 1;
  text-align: center;
  border: 3px solid #f2d812;
  border-radius: 5px;
}

.card-details-one {
  background: #fff;
  color: #000 !important;
  padding: 20px 15px;
  flex: 1;
  text-align: center;
  border: 3px solid #f2d812;
  border-radius: 5px;
}

.card-details-one a {
  color: #000;
}

.card-details-one a:hover {
  color: #d3a238;
}

.card-details-one span {
  margin: 0 auto -30px auto;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 6px solid #d7ac2c;
  border-radius: 50%;
  font-size: 30px;
  position: relative;
  top: -60px;
  font-family: "Avantt_semibold" !important;
  background: #fff;
  padding: 10px;
  width: 80px;
  height: 80px;
}

.four-dimensions p {
  margin-top: 0px !important;
  margin-bottom: 0;
}

.four-dimensions h4 {
  margin-top: -40px !important;
  margin-bottom: 0;
}

.card-details span {
  margin: 0 auto -30px auto;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 6px solid #d7ac2c;
  border-radius: 50%;
  font-size: 30px;
  position: relative;
  top: -60px;
  font-family: "Avantt_semibold" !important;
  background: #fff;
  padding: 10px;
  width: 80px;
  height: 80px;
}

.arrow-link {
  text-align: center;
  padding-bottom: 70px;
}

.arrow-link .go-to-link a {
  color: #000;
  border: 1px solid #f2d812;
  font-family: "Avantt", sans-serif;
  font-size: 19px;
  letter-spacing: 1px;
  padding: 12px 65px;
  border-radius: 3px;
  text-decoration: none;
  margin-top: 15px;
  display: inline-block;
  background: #f2d812;
}

.pdp-blk {
  background: #000;
}

.pdp-blk .whatis p {
  color: #fff;
}

.manager-in-lcm.pdp-blk .details {
  padding-top: 35px;
  width: calc(100% - 50px);
  margin: 0 25px;
  position: relative;
}

.manager-in-lcm.pdp-blk .details span {
  position: absolute;
  left: calc(50% - 25px);
  top: -30px;
}

.arrow-link-1 p {
  color: #fff;
  padding: 0 100px;
}

.arrow-link-1 {
  background: #000;
}

.remember {
  text-align: center;
  padding: 60px 0;
}

.spot-recover h3 {
  color: #fff;
  font-family: "Avantt_semibold", sans-serif;
  text-align: center;
  padding: 17px 0;
  margin: 0;
}

.spot-recover-main {
  color: #000;
  background: #fff !important;
}

.assesment-structure-blw.spot-recover-main .model .desc {
  color: #000;
}

.assesment-structure-blw.spot-recover-main .detail-list {
  width: 20%;
  margin-top: 50px;
}

.assesment-structure-blw.spot-recover-main .detail-list .details {
  background: #fff;
}

.details-ins-main {
  padding: 50px 0;
}

.details-ins-main h3 {
  text-align: center;
  font-size: 20px;
}

.details-ins {
  background: #f8f8f8;
  margin-bottom: 20px;
  padding: 25px;
  margin-top: 20px;
  text-align: center;
}

.common-table-people {
  padding: 60px 0;
}

.common-table-people table,
.common-table-people tr,
.common-table-people th,
.common-table-people td {
  border: 2px solid #fff !important;
  background: #f8f8f8;
}

.common-table-people thead,
.common-table-people tbody {
  border: 0 !important;
}

thead.table-light tr td {
  text-align: center;
  border: 2px solid #e4c221 !important;
  font-weight: 600;
}

.common-table-people th {
  background: #e4c221 !important;
  border: 0;
}

.f-wrap {
  flex-wrap: wrap !important;
}

/* 
ak css */
section.four-a-framework .d-flex {
  flex-wrap: wrap;
  justify-content: center;
}

a.white-btn {
  background: #fff;
  padding: 10px 16px;
  display: inline-block;
  border-radius: 5px;
  color: #000;
  text-decoration: none;
}

a.white-btn:hover {
  background: #e4c221;
  color: #000;
}

.single-four-a {
  box-shadow: 0px 2px 40px #e3d00229;
  background: #000;
  border: 2px solid #e4c221;
  border-radius: 5px;
  width: calc(33% - 20px);
  padding: 30px 16px;
  text-align: center;
}

.single-four-a p {
  color: #a4a4a4;
}

.single-four-a h3 {
  color: #e4c221;
  font-weight: 600 !important;
  font-size: 20px;
}

.essential-responsibility p {
  margin: 0;
}

.white a,
h4.white,
p.white,
a.white,
.white h1,
.white h2,
.white h3,
.white h4,
.white h5,
.white p,
.white span {
  color: #fff;
}

.four-a-list {
  text-align: left;
  margin-top: 16px;
}

.opr-t {
  padding-top: 10px;
  padding-bottom: 0;
}

.pb0 {
  padding-bottom: 0;
}

.details span.s-full {
  width: 102% !important;
  left: -3px !important;
  border-radius: 0 !important;
}

.both60 {
  padding: 60px 0px;
}

.p-top60 {
  padding-top: 60px;
}
.p-bottom60 {
  padding-bottom: 60px;
}

.mh-auto {
  min-height: auto;
}

.whatis ul {
  list-style: none;
}

.ta-left {
  text-align: left;
}

/* --------------AK NEW CSS--------------------- */

.top-banner p {
  margin: 0;
  color: #f4df04;
  font-size: 30px;
  text-transform: uppercase;
}

.top-banner h2 {
  font-size: 38px;
  font-weight: bold;
  max-width: 800px;
  margin: 0 auto;
}

.top-banner .details {
  padding: 0 15px;
}

/* all banners START*/
.top-banner {
  position: relative;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-bottom: 10px solid #f2d812;
}

.space50 {
  height: 50px;
}

.space80 {
  height: 80px;
}

.timeline img {
  width: 100%;
}

.key-takeaways img {
  width: 100%;
}

.key-takeaways .blk-btn {
  width: auto;
  display: inline-block !important;
  margin: 0 auto;
}

.lcm-banner {
  background-image: url(../images/banners/lcm.png);
}

.keyactions a {
  display: inline-block;
  background: #000;
  color: #fff;
  text-decoration: none;
  padding: 10px 30px;
  border-radius: 5px;
}

.action-inner.full_k {
  max-width: 100% !important;
}

.lpir-banner {
  background-image: url(../images/banners/lcm.png);
}

.er-banner {
  background-image: url(../images/banners/lcm.png);
}

.mdm .box-inner {
  flex: 0 0 18%;
  color: #fff;
}

.keyactions h3 a {
  /* color: #000; */
  text-decoration: none;
}

.keyactions h3 a:hover {
  color: #f2d812;
}

.campus-hiring-banner {
  background-image: url(../images/banners/lcm.png);
}

.cp-banner {
  background-image: url(../images/banners/lcm.png);
}

.mc-banner {
  background-image: url(../images/banners/lcm.png);
}

.ndc-banner {
  background-image: url(../images/banners/lcm.png);
}

.poe-banner {
  background-image: url(../images/banners/lcm.png);
}

.pdp-banner {
  background-image: url(../images/banners/pdp.png);
}

.engagement-banner {
  background-image: url(../images/banners/pdp.png);
}

.atie-banner {
  background-image: url(../images/banners/pdp.png);
}

.laow-banner {
  background-image: url(../images/banners/pdp.png);
}

.pl-banner {
  background-image: url(../images/banners/pdp.png);
}

.lmpl-banner {
  background-image: url(../images/banners/pdp.png);
}

.ul-banner {
  background-image: url(../images/banners/pdp.png);
}

.rediploy-banner {
  background-image: url(../images/banners/pdp.png);
}

.opr-banner {
  background-image: url(../images/banners/opr.png);
}

.common-blw-banner {
  background-image: url(../images/pattern-bg.jpg);
}

.keyactions {
  background-image: url(../images/pattern-bg.jpg);
}

.senior_manager_role {
  background-image: url(../images/pattern-bg.jpg);
}

.mu-banner {
  background-image: url(../images/banners/mu.png);
}

.details img {
  max-width: 60px;
}

.ass-stru-list.full-list {
  width: 100%;
}

.process-people,
.leaves-structure {
  background-image: url(../images/FirstPage_SubBanner1.png);
  padding: 60px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.buddy-emp {
  background-image: url(../images/FirstPage_SubBanner1.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.guiding_principles {
  background-image: url(../images/guiding-principle.png);
  padding: 80px 0;
}

.process_raci{
	background-image: url(../images/processes_raci.png);
}

.flowImage {
  width: 100%;
  margin-top: 40px;
}

.process_raci table thead th {
	background-color: #D9D9D9;
}

.bg-grey {
  background: #f5f5f5;
}

table.leave-table {
  background: #fff;
}

.redeployment-process h3 {
  color: #fff;
  text-align: center;
}

.text-left {
  text-align: left;
}

.common-blw-banner i {
  font-size: 10px;
}

.aid-you i {
  margin-right: 8px;
  font-size: 16px !important;
  color: #f2d812 !important;
}

.ul-eligibiliy {
  background-image: url(../images/FirstPage_SubBanner1.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.redeployment-process {
  background-image: url(../images/FirstPage_SubBanner1.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

table.leave-table td {
  text-align: left;
}

.text-dark-yellow {
  color: #A48719;
}

.special-leave {
  background-image: url(../images/FirstPage_SubBanner1.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.spot-recover {
  background-image: url(../images/bg4.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.four-a-framework {
  background-image: url(../images/bg1.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.when-opr-hpn {
  background-image: url(../images/bg3.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.four-dimensions {
  background-image: url(../images/bg3.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.essential-responsibility {
  text-align: center;
  background-image: url(../images/bg2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

h3 {
  font-family: "Avantt_semibold" !important;
}

i.fa-solid.fa-circle-check {
  color: rgb(185 185 185);
}

.process-people .d-flex {
  gap: 30px;
}

.assesment-structure-blw {
  color: #fff;
  background-image: url(../images/bg1.png);
  padding: 60px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-bottom: 5px solid #f2d812;
}

.loa-absense {
  background-image: url(../images/bg1.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-top: 5px solid #f2d812;
}

.ways-to-deal {
  background-image: url(../images/bg1.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-top: 5px solid #f2d812;
}

.benefits-summery {
  color: #fff;
  background-image: url(../images/bg1.png);
  padding: 60px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-top: 5px solid #f2d812;
}

.probation-extension-proces {
  background-image: url(../images/bg1.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-top: 5px solid #f2d812;
}

.m_role_process {
  background-image: url(../images/bg1.png);
  padding: 60px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-top: 5px solid #f2d812;
}

.questions .col-md-4,
.questions .col-md-3,
.questions .col-md-6,
.questions .col-md-12 {
  background: #f5f5f5;
  border: 5px solid #fff;
  padding: 30px;
  border-radius: 5px !important;
}

h3 {
  font-weight: 700 !important;
}

.when-opr-hpn .card-details span {
  width: 70%;
  max-width: 400px;
  border-radius: 5px;
  height: auto !important;
  font-size: 22px;
}

.no-call-show .card-details span {
  width: 70%;
  max-width: 400px;
  border-radius: 5px;
  height: auto !important;
  font-size: 22px;
}

.four-dimensions .card-details .s-full {
  width: 70%;
  max-width: 400px;
  border-radius: 5px;
  height: auto !important;
  font-size: 22px;
}

.gap20 {
  gap: 20px;
}

.go-to-link a:hover {
  color: #f2d812;
}

.go-to-link a {
  text-align: center !important;
  display: block;
  font-size: 40px;
  text-decoration: none;
  color: #b7b7b7;
  font-weight: 700;
  text-transform: uppercase;
}

.common-blw-banner i.fa.fa-chevron-down {
  border: 2px solid #000;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin: 0 auto;
}

.process-list-main i.fa.fa-angle-down {
  color: #c7c7c7;
  background: #fff;
  border: 2px solid #c7c7c7;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  font-size: 12px;
}

tbody.text-center td {
  text-align: center;
}

.emp-three-box a.yellow-btn {
  display: block;
  width: auto;
  text-align: center;
  padding: 9px 10px;
  margin: 0 auto !important;
}

.yellow-btn {
  background: transparent linear-gradient(89deg, #d5a732 0%, #f4de04 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 2px 40px rgb(0 0 0 / 8%);
  padding: 10px 25px;
  margin: 0 auto;
  color: #000;
  text-decoration: none;
  font-family: "Avantt_semibold";
  border: none;
  border-radius: 35px;
  display: inline-block;
  font-size: 18px;
  text-align: center;
  margin-bottom: 25px;
}
.yellow-btn:hover {
  color: #000;
  background: transparent linear-gradient(89deg, #d5a732 100%, #f4de04 0%) 0% 0%
    no-repeat padding-box;
}
.yellow-btn i {
  margin-right: 8px;
}

.sc-dkzDqf {
  font-family: "Avantt_semibold";
  font-size: 18px;
}

.clLvve.selected {
  background-color: #000 !important;
}

.dPOxbO {
  border: 0 !important;
  border-radius: 20px !important;
  background: transparent linear-gradient(89deg, #d5a732 0%, #f4de04 100%) 0% 0%
    no-repeat padding-box;
  color: #000;
  padding: 4px 7px;
}

.dPYZTB {
  color: #000 !important;
  font-weight: 600 !important;
  cursor: pointer !important;
  background: transparent linear-gradient(89deg, #d5a732 0%, #f4de04 100%) 0% 0%
    no-repeat padding-box !important;
  border-radius: 25px !important;
  margin-bottom: 10px !important;
  padding: 1px 5px !important;
  font-size: 14px !important;
}

.date-select {
  display: inline-block;
  margin-left: 15px;
  margin-bottom: 25px;
}

/*
a.yellow-btn i {
	margin-left: 20px;
	font-size: 30px;
}



a.yellow-btn {
	display: flex;
	align-items: center;
}

a.yellow-btn span {
	text-align: left;
}*/

table.table tbody tr td {
  text-align: left;
  padding: 30px 20px;
}

.emp-three-box .process-list-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

table.table {
  table-layout: fixed;
}

table.table th {
  padding: 10px 20px;
  text-align: left;
}

.mu-counts .card-details {
  flex: 0 0 32%;
}

.mu-counts {
  row-gap: 70px;
}

table.leave-table td,
table.leave-table th {
  border: 1px solid #000;
}

.keyactions .action-inner {
  background: #fff;
  padding: 30px;
  margin: 0 auto;
  max-width: 720px;
  border: 1px solid;
  border-radius: 5px;
}

.keyactions h3 {
  text-align: center;
  margin-bottom: 0 !important;
}

.ui {
  text-decoration: none;
}

/* all banners END*/
.questions {
  margin-bottom: 60px;
}

.event-div {
  display: inline-block;
  height: 200px !important;
  max-height: 200px;
  margin-bottom: 30px;
}

.event-div .list-box,
.event-div .list-box a {
  height: 100% !important;
}

.d-flex-e {
  display: flex;
  flex-wrap: wrap;
}

.modal-content {
  background: #fff !important;
  box-shadow: none;
  border: 0 !important;
}

.modal-content .modal-header button.btn.btn-close {
  opacity: 1;
  color: #000;
}

.modal-content .modal-header {
  background: #f2d812;
  color: #000;
}

.new-post-li {
  width: 100% !important;
}

.modal-content .modal-body {
  padding: 0;
}

.modal-content
  .modal-body
  ul.new-posts
  li
  .post-list-main
  .user-details
  .details
  h4,
.modal-content
  .modal-body
  ul.new-posts
  li
  .post-list-main
  .user-details
  .details
  p,
.modal-content .modal-body .title-desc,
.modal-content .modal-body ul.new-posts li .post-list-main .comment-shortlist {
  color: #000;
}

.modal-content
  .modal-body
  ul.new-posts
  li
  .post-list-main
  .user-details
  .details
  h4 {
  font-size: 18px;
}

.modal-content
  .modal-body
  ul.new-posts
  li
  .post-list-main
  .user-details
  .details
  p {
  font-size: 16px;
}

.modal-content .modal-body ul.new-posts li .post-list-main .title-desc h6 {
  font-family: "Avantt";
  font-size: 17px;
  margin: 0;
}

.modal-content
  .modal-body
  ul.new-posts
  li
  .post-list-main
  .comment-shortlist
  .shortlist {
  cursor: pointer;
}

.modal-content .modal-body ul.new-posts li .post-list-main .user-details {
  position: relative;
}

.modal-content
  .modal-body
  ul.new-posts
  li
  .post-list-main
  .user-details
  .comment-shortlist {
  position: absolute;
  top: 9px;
  right: 0;
  width: auto;
  margin: 0;
  padding: 0;
}

.modal-content
  .modal-body
  ul.new-posts
  li
  .post-list-main
  .comment-shortlist
  .comment {
  float: none;
  display: inline-block;
}

.modal-content
  .modal-body
  ul.new-posts
  li
  .post-list-main
  .comment-shortlist
  .shortlist {
  float: none;
  display: inline-block;
  padding-left: 10px;
}

.modal-content
  .modal-body
  ul.new-posts
  li
  .post-list-main
  .comment-shortlist
  .shortlist
  span {
  padding-left: 5px;
  padding-right: 0;
}

.modal-content .modal-body ul.new-posts li .post-list-main .comment-shortlist {
  padding-top: 0;
  margin-top: 0;
}

.modal-content
  .modal-body
  ul.new-posts
  li
  .post-list-main
  .comment-shortlist
  h4 {
  font-size: 19px;
}

.modal-content
  .modal-body
  ul.new-posts
  li
  .post-list-main
  .comment-shortlist
  p {
  font-size: 15px;
}

.modal-content .modal-body ul.new-posts li {
  margin-bottom: 0;
}

.commnent-modal-show {
  background: #f2d812;
  padding: 20px !important;
  width: calc(100% + 42px) !important;
  margin-left: -21px;
  top: 27px;
  position: relative;
  border-radius: 0 0px 5px 5px;
}

.commnent-modal-show button.btn.btn-primary,
.create-post-form button.btn.btn-primary {
  background: #000;
  border: 1px solid #000;
}

.create-post-form {
  padding: 25px;
}

.nav-tabs-results .nav {
  margin-bottom: 25px;
}

@media only screen and (max-width: 767px) {
  .banner-home-details .user-name {
    padding-top: 15px;
  }

  .handbook-list-home {
    padding: 30px 0;
  }

  .handbook-list-home .heading {
    margin-bottom: 30px;
    font-size: 20px;
  }

  .forums-main {
    padding: 30px 0;
  }

  .forums-main .create-new-post {
    padding: 5px 15px;
    font-size: 18px;
  }

  .post-list li {
    padding: 15px;
  }

  .common-banner .banner-details .banner-details-inner .details p {
    font-size: 23px;
  }

  .event-div {
    width: 100%;
  }

  .common-banner .banner-details .banner-details-inner .details .desc {
    text-transform: inherit;
    font-size: 14px;
    margin: 0;
    line-height: 14px;
  }

  .common-banner .banner-details .banner-details-inner .details p {
    text-transform: uppercase;
    font-size: 20px;
    font-family: "Avantt_semibold", sans-serif;
    color: #000;
    margin: 0;
  }

  ul#tabs-nav li {
    width: 100%;
    padding: 0px;
  }

  #tabs-nav li a {
    font-size: 19px;
    margin-bottom: 15px;
    font-family: "Avantt_semibold", sans-serif;
  }

  .handbook-list li {
    margin-bottom: 20px;
    width: 50%;
    padding: 0 15px;
    min-height: 50px;
  }

  .handbook-list li a {
    font-size: 16px;
  }

  .list-banner {
    width: 100% !important;
    margin: 10px 0 0 0;
  }

  .banner-right-details .list-banner a .inner-bg h4 {
    margin: 0 0 5px 0;
    min-height: inherit;
    font-size: 17px;
  }

  .banner-right-details .list-banner a .inner-bg p {
    font-size: 14px;
  }

  .search-banner p.head {
    font-size: 16px;
  }

  .All-topics h4 {
    font-size: 16px;
  }

  .All-topics .scroll-down {
    font-size: 23px;
  }

  .search-dropdown {
    z-index: 10000;
  }

  .banner-right-details .list-banner a {
    height: 140px;
  }

  .banner-right-details .list-banner a .inner-bg br {
    display: none;
  }

  .banner-home-details .user-name span {
    font-size: 16px;
  }

  .banner-home-details .user-name h3 {
    font-size: 32px;
  }

  .search-banner {
    padding-top: 0;
  }

  .banner-right-details {
    padding: 0;
  }

  .fa-star:before {
    content: "\f005";
    color: aliceblue;
  }
}

.add-to-fav-banner i {
  width: 25px;
  height: 25px;
  background: #fff;
  border-radius: 50%;
  line-height: 27px;
  margin-left: 10px;
}

.fav-banner i {
  width: 25px;
  height: 25px;
  background: #fff;
  color: #000;
  border-radius: 50%;
  line-height: 27px;
  margin-left: 10px;
}

.add-to-fav-banner {
  position: absolute;
  right: 25px;
  cursor: pointer;
  bottom: 30px;
  color: #999;
  padding: 10px 20px;
  background: rgba(255, 255, 255, 0.2196078431);
  border-radius: 20px;
}

.favourite-section button {
  background: rgba(0, 0, 0, 0.5);
  border: none;
  padding: 7px 10px;
  margin-top: 12px;
  border-radius: 5px;
  color: #fff;
  display: flex;
  align-items: center;
}

.favourite-section button i {
  width: 20px;
  height: 20px;
  color: #000;
  background: #f2d812;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border-radius: 50%;
  font-size: 8px;
}

.favourite-section hr {
  height: 2px;
  background: #707070;
  opacity: 1 !important;
}

.fav-card-conatiner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px;
}

.fav-card-conatiner .fav-card {
  width: calc(50% - 8px);
  overflow: hidden;
  background: #ffffff38;
  padding: 20px 10px 10px 10px;
  border-radius: 5px;
  color: #fff;
  position: relative;
}

.fav-card-conatiner .fav-card p {
  margin: 0;
  font-size: 16px;
}

.fav-card i.fa.fa-bookmark {
  position: absolute;
  top: 0;
  left: 15px;
  color: #f2d812;
}

.fav-card i.fa.fa-times-circle:hover {
  color: #f2d812;
  cursor: pointer;
}

.fav-card i.fa.fa-times-circle {
  position: absolute;
  top: 5px;
  right: 3px;
  color: #999;
  font-size: 13px;
}

a.v-all {
  display: block;
  color: #f2d812;
  text-align: right;
  margin-top: 8px;
}

a.v-all:hover {
  color: #fff;
}

.eye {
  position: absolute;
  right: 15%;
}

li.add-to-fav-menu {
  position: relative;
}

.add-to-fav-menu span.add {
  width: 25px;
  height: 25px;
  background: #f2d812;
  color: #000;
  line-height: 26px;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
}

.dropdown-fav-show {
  position: absolute;
  top: 40px;
  right: -50px;
  width: 550px;
  background: #333;
  padding: 20px 10px;
  border-radius: 25px;
  text-align: left;
  display: none;
}

.fav-main-list {
  max-height: 400px;
  overflow: auto;
  padding: 0 20px;
}

.fav-main-list::-webkit-scrollbar {
  width: 8px;
}

.fav-main-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.fav-main-list::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 7px;
}

.fav-main-list::-webkit-scrollbar-thumb:hover {
  background: #999;
}

.fav-card-conatiner {
  max-height: 192px;
  overflow: auto;
  padding-right: 10px;
}

.fav-card-conatiner::-webkit-scrollbar {
  width: 8px;
}

.made-by-upscale a {
  color: #fff;
}

.made-by-upscale a:hover {
  color: #f2d812;
}

.made-by-upscale a img {
  width: 100px;
}

.fav-card-conatiner::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.fav-card-conatiner::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 7px;
}

.fav-card-conatiner::-webkit-scrollbar-thumb:hover {
  background: #999;
}

.dropdown-fav-show {
  border: 1px solid #f9f9f966;
}

.dropdown-fav-show:after,
.dropdown-fav-show:before {
  bottom: 100%;
  left: 88%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.dropdown-fav-show:after {
  border-color: rgba(51, 51, 51, 0);
  border-bottom-color: #333;
  border-width: 10px;
  margin-left: -10px;
}

.dropdown-fav-show:before {
  border-color: rgba(249, 249, 249, 0);
  border-bottom-color: #f9f9f966;
  border-width: 11px;
  margin-left: -11px;
}

.dropdown-fav-show .fav-heading {
  border-bottom: 1px solid #f9f9f966;
  color: #fff;
  margin-bottom: 20px;
  padding-bottom: 10px;
  width: calc(100% - 40px);
  margin-left: 20px;
}

.dropdown-fav-show .fav-heading i {
  width: 20px;
  height: 20px;
  background: #f2d812;
  color: #000;
  text-align: center;
  line-height: 24px;
  margin-right: 10px;
  border-radius: 50%;
  font-size: 13px;
}

.dropdown-fav-show .fav-main-list a p {
  font-size: 14px;
}

.dropdown-fav-show .fav-main-list a {
  color: #fff;
  text-decoration: none;
}

.mobile-menu-icons {
  text-align: right;
}

.banner-right-details .list-banner a .inner-bg .left-upcoming {
  float: left;
  width: 40%;
  height: 175px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0 5px 5px 0;
}

.banner-right-details .list-banner a .inner-bg .left-upcoming span.date {
  color: #f2d812;
  display: block;
  font-size: 19px;
  padding-top: 60px;
  font-family: "Avantt_semibold", sans-serif;
}

.banner-right-details .list-banner a .inner-bg .left-upcoming span.fa {
  display: block;
  color: #f2d812;
  padding-top: 5px;
  font-size: 19px;
  font-family: "Avantt_semibold", sans-serif;
}

.banner-right-details .list-banner a .inner-bg .right-upcoming {
  float: left;
  width: 60%;
  text-align: left;
  padding: 15px;
}

.banner-right-details .list-banner a .inner-bg .right-upcoming span {
  background: #f2d812;
  height: 3px;
  width: 30px;
  display: inline-block;
}

.banner-right-details .list-banner a .inner-bg .right-upcoming p {
  font-size: 15px;
  font-family: "Avantt", sans-serif;
}

.not-page {
  padding: 50px 0;
}

.not-page .text-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.not-page .title {
  font-size: 5em;
  font-weight: 700;
  color: #f2d812;
  font-family: "Avantt_semibold", sans-serif;
}

.not-page .subtitle {
  font-size: 40px;
  font-weight: 700;
  color: #000;
}

.not-page .isi {
  font-size: 18px;
  text-align: center;
  margin: 30px 0;
  padding: 0px;
  color: #000;
}

.not-page .buttons .button {
  margin: 30px;
  font-weight: 700;
  border: 0;
  text-decoration: none;
  padding: 15px 25px;
  text-transform: uppercase;
  color: #000;
  font-family: "Avantt_semibold", sans-serif;
  background: transparent linear-gradient(270deg, #f5e003 0%, #d6a732 100%) 0%
    0% no-repeat padding-box;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  font-size: 20px;
  text-decoration: none;
}

.not-page .buttons .button:hover {
  background: transparent linear-gradient(270deg, #f5e003 0%, #d6a732 100%) 0%
    0% no-repeat padding-box;
  color: #000;
  transition: all 0.2s ease-in-out;
}

a.my-anchor-css-className {
  background: #f2d812 !important;
  width: auto !important;
  display: inline-block !important;
  clear: both;
  padding: 0 6px !important;
  font-size: 15px;
  float: left;
}

a.my-anchor-css-className:hover {
  color: #000 !important;
}

.show-line-ellipse {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.dark-strip {
  background: #000;
  padding: 18px;
  text-align: center;
  color: #fff;
}

.dark-strip p {
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .nav-header-middle > li:hover > ul {
    height: 400px;
    overflow-y: auto;
  }

  .banner-home {
    background-size: inherit;
  }

  .header-custom {
    padding: 20px 0 !important;
  }

  .menu-header-right {
    position: relative;
  }

  li.add-to-fav-menu {
    position: static;
  }

  .mobile-menu-icons {
    width: 100%;
    text-align: center !important;
  }

  .dropdown-fav-show {
    width: 100%;
    right: 0;
  }

  .logo-header span {
    display: block;
    border-left: 0;
    margin-left: 0;
    padding-left: 30px;
  }

  .menu-header-right li {
    padding: 0 12px;
  }

  .All-topics {
    padding-top: 25px;
  }

  .list-banner .carousel-control-prev {
    left: 0;
  }

  .list-banner .carousel-control-next {
    right: 0;
  }

  #tabs-nav > li > a br {
    display: none;
  }

  ul#tabs-nav > li.active .arrow_box {
    display: none;
  }

  .footer-btm .d-flex {
    display: block !important;
  }

  .privacy-policy a {
    display: block;
    width: 100%;
    text-align: center;
  }

  .nav-header-middle > li:hover > ul {
    right: -45px;
    width: 330px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1299px) {
  .nav-header-middle > li:hover > ul {
    width: 930px;
  }

  .nav-header-middle > li:hover > ul:after,
  .nav-header-middle > li:hover > ul:before {
    left: 67%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-header-middle > li:hover > ul {
    right: -185px;
    width: 735px;
  }

  .nav-header-middle > li:hover > ul:after,
  .nav-header-middle > li:hover > ul:before {
    left: 67%;
  }

  .handbook-list-home .container {
    max-width: 100%;
  }

  ul#tabs-nav > li {
    padding: 0 5px;
  }

  #tabs-nav > li > a {
    min-height: 100px;
    font-size: 17px;
    word-break: break-all;
  }

  .banner-right-details .list-banner a .inner-bg .left-upcoming span.date {
    padding-top: 12px;
  }

  .banner-right-details .list-banner a .inner-bg .left-upcoming {
    height: 75px;
    width: 100%;
  }

  .banner-right-details .list-banner a .inner-bg .right-upcoming {
    width: 100%;
    padding: 0 15px 15px 15px;
  }

  .list-btm-banner a .inner-bg p {
    padding: 0 10px;
  }

  .list-banner {
    height: 235px;
  }

  .treding-heading-main .w-50:nth-child(3) {
    display: none;
  }

  .treding-heading-main a {
    height: 140px !important;
    margin-bottom: 4px;
  }

  .fav-card-conatiner .fav-card {
    width: calc(100% - 8px);
  }
}

.under-line {
  border-bottom: 1px solid #000 !important;
}
.heading-start {
  margin: 15px 0 0 0;
}

.rmdp-input {
  width: 100%;
  height: 40px !important;
}

.start-end {
  margin-top: 30px;
}

.start-end .list-row {
  float: left;
  padding-right: 10px;
  width: 200px;
}

.start-end .list-row .submit-btn {
  background: #000;
  color: #fff;
  border-radius: 5px;
  border: 1px solid #000;
  height: 38px;
  width: 200px;
  margin-top: 46px;
}

.search-form-admin {
  padding-top: 20px;
  padding-bottom: 10px;
}

.approval-csv {
  margin-bottom: 10px;
}

.custom-tabledbm p {
  margin: 0 !important;
  font-size: 15px !important;
  text-align: center !important;
}

.custom-tabledbm tbody {
  background: #fff;
}

.custom-tabledbm tbody tr td {
  padding: 0.5rem 0.5rem;
}

table.custom-tabledbm td,
table.custom-tabledbm th {
  border: 1px solid #000 !important;
}

table.custom-tabledbm th,
table.custom-tabledbm td {
  padding: 10px !important;
  vertical-align: middle;
}

table.custom-tabledbm thead td {
  background: #f2da23;
  vertical-align: middle;
  width: auto;
}

table.custom-tabledbm tbody th {
  font-size: 15px !important;
}

table.custom-tabledbm thead td:nth-child(2) {
  width: 220px !important;
}

table.custom-tabledbm thead td:nth-child(5) {
  width: 150px !important;
}

.bg-row {
  background: #f2da23;
}

.font-size-rd {
  font-size: 25px;
}

.feedback-btn {
  position: fixed;
  bottom: 10px;
  font-size: 14px !important;
  right: 10px;
  z-index: 100000;
}

.curser-pointer {
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

.accordion-button:not(.collapsed) {
  color: #fff !important;
  background: #000 !important;
}
.accordion-button:not(.collapsed)::after {
  filter: invert(1);
}

/* ============================= */
.accordion {
  background: none !important;
  padding: 0 !important;
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
}
div#accordionExample:hover {
  background: none;
}
.active,
.accordion:hover {
  background-color: #ccc;
}

.questions .accordion:after {
  content: "\002B";
  color: #777;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.questions .active:after {
  content: "\2212";
}

.questions .panel {
  padding: 0 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
}

div#accordionExample button {
  padding: 14px 16px;
}

.questions button.accordion.active {
  background: #000 !important;
  color: #fff !important;
}
.questions button.accordion {
  padding: 14px 16px !important;
  background: #eeeeee !important;
  margin: 5px 0px;
}
/* .process-list-main a {
    width: 100% !important;
} */

a.faq-doc {
  margin-bottom: 0 !important;
  font-size: 18px;
}

.bottomButtons a {
  background: #000;
  flex: 1;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
}

.bottomButtons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.bottomButtons a:hover {
  background: transparent linear-gradient(270deg, #f5e003 0%, #d6a732 100%) 0%
    0% no-repeat padding-box;
  color: #000;
}

h2.videoTitle {
  font-family: "Avantt_semibold" !important;
  color: #000;
  font-size: 28px;
}

.pdfdata {
  display: flex;
  justify-content: space-between;
}

.pdfdata a {
  color: #000;
  font-size: 22px;
  font-family: "Avantt_semibold";
}
.pdfdata a:hover {
  color: #f2da23;
}

.banner-home .carousel-item.active {
  background: none !important;
}
.css-1anqmj6-MuiPopper-root-MuiPickersPopper-root {
  z-index: 100000 !important;
}
.lcm-under-line {
  text-decoration: none;
  color: white;
  border-bottom: 1px solid #f2d812 !important;
}
.lcm-under-line:hover {
  color: #f2d812 !important;
}
.desc a.yellow-btn {
  background: transparent linear-gradient(89deg, #d5a732 0%, #f4de04 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.08);
  padding: 10px 25px;
  margin: 0 auto;
  color: #000;
  text-decoration: none;
  font-family: "Avantt_semibold";
  border: none;
  border-radius: 35px;
  display: inline-block;
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
}
.strong-content {
  margin-top: 30px;
}
ul.strong-list {
  list-style: none;
  padding: 0;
  margin: 30px 0 0 0;
  background: #fff;
  color: #000;
  display: inline-block;
  width: 100%;
}
ul.strong-list li:first-child,
ul.strong-list li:last-child {
  float: left;
  width: 40%;
}
ul.strong-list li:first-child .details-list-strong,
ul.strong-list li:last-child .details-list-strong {
  width: 50%;
  float: left;
  border: 5px solid #fff;
}
ul.strong-list li:first-child .details-list-strong h4 {
  background: hsl(3.96deg 80.53% 55.69%);
  text-align: center;
  color: #fff;
  font-size: 18px;
  line-height: 38px;
}
ul.strong-list li:first-child .details-list-strong p {
  background: #f8ded5;
  padding: 10px;
  min-height: 95px;
  margin: 0 0 5px 0;
}
ul.strong-list li:first-child .fav-ne-unfav {
  background: #f8ded5;
  float: left;
  width: calc(100% - 10px);
  margin: 0 0 5px 5px;
  text-align: center;
  text-transform: uppercase;
  line-height: 40px;
  font-weight: 600;
}
ul.strong-list li:last-child .details-list-strong h4 {
  background: #5ab152;
  text-align: center;
  color: #fff;
  font-size: 18px;
  line-height: 38px;
}
ul.strong-list li:last-child .details-list-strong p {
  background: #e2f0d9;
  padding: 10px;
  min-height: 95px;
  margin: 0 0 5px 0;
}
ul.strong-list li:last-child .fav-ne-unfav {
  background: #e2f0d9;
  float: left;
  width: calc(100% - 10px);
  margin: 0 0 5px 5px;
  text-align: center;
  text-transform: uppercase;
  line-height: 40px;
  font-weight: 600;
}
ul.strong-list li.middle-list {
  float: left;
  width: 20%;
  border-top: 5px solid #fff;
  border-bottom: 5px solid #fff;
}
ul.strong-list li.middle-list .details-list-strong h4 {
  background: #f7c033;
  text-align: center;
  color: #fff;
  font-size: 18px;
  line-height: 38px;
}
ul.strong-list li.middle-list .details-list-strong p {
  background: #fcebd2;
  padding: 10px;
  min-height: 95px;
  margin: 0 0 5px 0;
}
ul.strong-list li.middle-list .fav-ne-unfav {
  background: #fcebd2;
  float: left;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  line-height: 40px;
  font-weight: 600;
  margin-top: 5px;
}
.not-fav {
  position: relative;
  top: -5px;
  width: 60%;
}
.not-fav img {
  width: 100%;
}
.not-fav p {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}
.example-strong h4 {
  font-size: 28px;
}
.example-strong p {
  font-style: italic;
  font-size: 18px;
}
.strong-percentage {
  text-align: center;
}
.strong-percentage img {
  width: 60%;
}
.improvements-strong h3 {
  text-align: center;
  background: #f5e003;
  padding: 10px 20px;
  margin-top: 30px;
  color: #000;
}
.improvements-strong ul {
  padding: 20px 40px;
  margin: 0;
}
.framework-left h5 {
  color: #002060;
}
.framework-left h3 {
  margin-top: 30px;
}
.framework-left p {
  color: #002060;
  font-size: 17px;
  font-weight: 500;
  line-height: 30px;
}
.framework-left ul li {
  font-size: 17px;
  font-weight: 500;
  line-height: 33px;
}
.framework-right .impact {
  text-align: center;
}
.framework-right .impact span.three {
  color: #002060;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 15px;
  display: inline-block;
  width: 100%;
}
.framework-right h3 {
  display: inline-block;
  color: #5dc4b8;
  text-transform: uppercase;
  font-weight: 900 !important;
  font-size: 50px;
  line-height: 45px;
  position: relative;
}
.framework-right h3 span:before {
  content: "";
  width: 44px;
  height: 2px;
  background: #ccc;
  position: absolute;
  left: 0px;
  bottom: 20px;
}
.framework-right h3 span:after {
  content: "";
  width: 44px;
  height: 2px;
  background: #ccc;
  position: absolute;
  right: 0px;
  bottom: 20px;
}
.principle-framework {
  padding: 50px 0;
}
.framework-right ul {
  list-style: none;
  padding: 0;
  margin: 20px 0;
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
}
.framework-right ul li {
  float: left;
  width: 33.33%;
  text-align: center;
  border-right: 1px solid #ccc;
}
.framework-right ul li img {
  height: 60px;
}
.framework-right ul li h4 {
  font-size: 14px;
  color: #5dc4b8;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 0;
}
.framework-right ul li h4 span {
  text-transform: capitalize;
  color: #666;
}
.framework-right ul li:last-child {
  border-right: 0;
}
.framework-right p {
  color: #002060;
  font-size: 17px;
  font-weight: 500;
  line-height: 30px;
}
ul.framework-list {
  list-style: none;
  padding: 0;
  margin: 20px 0 0 0;
  display: inline-block;
  width: 100%;
  color: #fff;
}
ul.framework-list > li {
  float: left;
  padding: 30px 20px;
  text-align: center;
  width: 33.33%;
  min-height: 355px;
}
ul.framework-list > li:nth-child(1) {
  background: #3c8ea8;
}
ul.framework-list > li:nth-child(2) {
  background: #f0b136;
}
ul.framework-list > li:nth-child(3) {
  background: #3c7f65;
}
ul.framework-list > li h4 {
  font-size: 40px;
}
ul.framework-list li ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 15px;
}
ul.framework-list li ul li span {
  border-radius: 50%;
  width: 7px;
  height: 7px;
  background: #fff;
  display: inline-block;
  margin-right: 10px;
}
ul.framework-list li ul li {
  position: relative;
  line-height: 30px;
}
.pdp-main-content {
  display: inline-block;
  width: 100%;
  margin-bottom: 30px;
}
.pdp-main-content .pdp-main-content-left {
  width: 30%;
  float: left;
}
.pdp-main-content .pdp-main-content-left img {
  width: 100%;
  max-width: 88%;
}
.pdp-main-content .pdp-main-content-right {
  float: left;
  width: 70%;
  line-height: 28px;
}
.success-dev {
  padding: 50px 0;
}
.success-dev .table-success {
  border: 0;
}
.success-dev .table-success tbody {
  border: 0;
}
.success-dev .table-success tbody tr td:nth-child(1) {
  background: #f6ecd7;
  border-bottom: 1px solid #fff;
  width: 200px;
  font-weight: 600;
  padding: 20px;
}
.success-dev .table-success tbody tr td:nth-child(2) {
  width: 80%;
  background: #fff;
  border-bottom: 1px solid #d3a338;
  padding: 10px;
  border-right: 1px solid #d3a338;
}
.success-dev .table-success thead th {
  background: #d3a338;
  border-right: 2px solid #fff;
  border-bottom: 0;
}
.success-dev .table-success thead th:nth-child(1) {
  width: 200px;
}
.success-dev .table-success thead th:nth-child(2) {
  border-right: 1px solid #d3a338;
}
.success-dev .table-success tbody tr td ul {
  list-style: none;
}
.success-dev .table-success tbody tr td ul li span {
  width: 10px;
  height: 10px;
  border: 2px solid #999;
  display: inline-block;
  margin-right: 10px;
}
.tips h4 {
  background: #f2f2f2;
  position: relative;
  text-transform: uppercase;
  padding: 0 15px;
  font-weight: 600;
  line-height: 60px;
}
.tips h4 img {
  position: absolute;
  top: -14px;
  right: -12px;
  width: 69px;
}
.tips .tips-content {
  background: #f2f2f2;
  padding: 15px;
}
.tips .tips-content p {
  letter-spacing: 1px;
  line-height: 28px;
}
.tips .tips-content ul {
  list-style: none;
}
.tips .tips-content ul li {
  position: relative;
  line-height: 29px;
}
.tips .tips-content ul li span {
  width: 10px;
  height: 10px;
  border: 2px solid #000;
  display: inline-block;
  margin-right: 10px;
}
.view-pdp {
  text-align: center;
  margin: 50px 0;
}
.view-pdp a {
  display: inline-block !important;
  background: #000 !important;
  color: #fff !important;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
}
.remember {
  position: relative;
}
.remember img.remember-left {
  position: absolute;
  left: -1px;
  top: 100px;
}
.remember img.remember-right {
  position: absolute;
  right: -1px;
  top: 100px;
}
.remember h4 {
  font-size: 30px;
}
.remember ul {
  list-style: none;
  padding: 0 150px;
  margin: 30px 0 0 0;
  display: inline-block;
  width: 100%;
}
.remember ul li {
  display: inline-block;
  width: 25%;
  vertical-align: top;
}
.remember ul li h4 {
  font-size: 16px;
  margin-top: 10px;
  line-height: 25px;
}
.remember ul li img {
  width: 100px;
}
.lda-main {
  padding: 50px 0;
}
.lda-main ul {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
}
.lda-main ul:before {
  content: "";
  position: absolute;
  top: 22px;
  left: 0;
  right: 0;
  border-top: 3px solid #ccc;
}
.lda-main ul li {
  float: left;
  width: 33.33%;
  text-align: center;
}
.lda-main ul li span {
  background: linear-gradient(
    to right top,
    #d3a238,
    #deb031,
    #e7c028,
    #efd01b,
    #f5e003
  ) !important;
  display: inline-block;
  padding: 10px;
  text-transform: uppercase;
  font-weight: 600;
  width: 300px;
  border-radius: 25px;
  position: relative;
}
.lda-main ul .arrow_box {
  position: relative;
  background: #ccc;
  top: 23px;
}
.lda-main ul .arrow_box:after {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(204, 204, 204, 0);
  border-left-color: #ccc;
  border-width: 15px;
  margin-top: -15px;
}
.lda-list .lda-box p {
  min-height: 94px;
}
.lda-list .lda-box {
  width: 300px;
  float: left;
  background: #ccc;
  border-radius: 10px;
  margin: 19px 66px;
  padding: 15px 10px;
}
.lda-list {
  display: flex;
  width: 100%;
  text-align: center;
}
.learning-developement {
  text-align: center;
  padding: 60px 0px;
}
.learning-developement a.cta,
.learning-developement a.cta:focus {
  background: #f5e003;
  border: #f5e003;
  color: #000;
  font-family: "Avantt_semibold", sans-serif;
  text-decoration: none;
  display: inline-block;
  padding: 10px 40px;
  font-size: 22px;
  margin: 30px 0 10px 0;
  border-radius: 10px;
}
.fullmanual_link {
  text-align: center;
  color: #fff !important;
  padding-top: 20px !important;
  text-decoration: underline;
  font-style: italic;
}
.fullmanual_link:hover {
  color: #f5e003 !important;
}
.img-responsive {
  max-width: 100%;
}
